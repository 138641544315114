<template>
  <div class="applyChain">
    <div class="main_apply">
      <div class="tab_box">
        <span
          @click="changeTab(item.id)"
          :class="['head_tab_item', item.id == currentType ? 'activeTab' : '']"
          v-for="(item, index) in tabList"
          :key="index"
        >
          {{ item.name }}({{ item.count }})
        </span>
      </div>
      <div class="filter_type">
        <div class="filter_item">
          <el-input
            @change="filterinputChange"
            v-model="filterForm.name"
            :placeholder="placeholderValue"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
        <div class="filter_item">
          <span class="labelName">审核状态：</span>
          <el-select
            @change="filterselectChange"
            v-model="filterForm.examine_status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in filterStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table">
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            v-for="(item, index) in mainColum"
            :key="index"
          >
            <template slot-scope="scope">
              <span v-if="item.type == 'text'">{{
                scope.row[item.prop] ? scope.row[item.prop] : '-'
              }}</span>
              <div
                style="display: flex; align-items: center"
                v-else-if="item.type == 'temp' && item.prop == 'examine_status'"
              >
                <span
                  :style="{
                    color:
                      scope.row[item.prop] == 0
                        ? '#bfbfbf'
                        : scope.row[item.prop] == 1
                        ? '#00a854'
                        : 'red',
                  }"
                  >{{
                    scope.row[item.prop] == 0
                      ? '待审核'
                      : scope.row[item.prop] == 1
                      ? '审核通过'
                      : '已驳回'
                  }}{{
                    scope.row[item.prop] == 2 && scope.row.examine_info
                      ? '(' + scope.row.examine_info + ')'
                      : ''
                  }}</span
                >
              </div>
              <!-- 待审核 审核通过 审核未通过  -->
              <div v-else-if="item.type == 'temp' && item.prop == 'operation'">
                <span
                  :style="{
                    color:
                      scope.row.examine_status == 0
                        ? 'rgb(100,118,255)'
                        : '#bfbfbf',
                    cursor: 'pointer',
                    display: 'inline-block',
                    margin: '0 10px',
                  }"
                  @click="handlerPass(scope.row)"
                  >通过</span
                >
                <span
                  :style="{
                    color:
                      scope.row.examine_status == 0
                        ? 'rgb(100,118,255)'
                        : '#bfbfbf',
                    cursor: 'pointer',
                    display: 'inline-block',
                    margin: '0 10px',
                  }"
                  @click="handlerNoPass(scope.row)"
                  >驳回</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <div class="footer_left">
          <el-button @click="mulHandlerPass" :disabled="!checkBox.length"
            >批量通过</el-button
          >
          <el-button @click="mulHandlerNopass" :disabled="!checkBox.length"
            >批量驳回</el-button
          >
        </div>
        <div class="footer_right">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="changeForm.page"
            :page-sizes="[8, 16, 24, 32]"
            :page-size="changeForm.limit"
            layout="sizes, prev, pager, next"
            :total="total_count"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        title="审核驳回"
        :visible.sync="showNopass"
        width="30%"
      >
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="驳回原因" prop="examine_info">
              <el-input v-model="ruleForm.examine_info"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="sureShowNoPass('ruleForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rowListDate: '',
      rules: {
        examine_info: [
          { required: true, message: '请输入驳回原因', trigger: 'blur' },
        ],
      },
      ruleForm: { examine_info: '' },
      showNopass: false,
      checkBox: [],
      total_count: 10,
      changeForm: {
        page: 1,
        limit: 8,
      },
      placeholderValue: '请输入你需要搜索的名称',
      tagFilter: [
        { value: '全部', label: '全部' },
        {
          value: '1年',
          label: '1年',
        },
        {
          value: '2年',
          label: '2年',
        },
        {
          value: '3年',
          label: '3年',
        },
        {
          value: '4年',
          label: '4年',
        },
        {
          value: '5年',
          label: '5年',
        },
        {
          value: '6年',
          label: '6年',
        },
        {
          value: '7年',
          label: '7年',
        },
        {
          value: '8年',
          label: '8年',
        },
        {
          value: '9年',
          label: '9年',
        },
        {
          value: '10年',
          label: '10年',
        },
      ],
      tableData: [],
      filterForm: {
        name: '',
        examine_status: 3,
      },
      currentType: -1,
      filterStatus: [
        {
          value: 3,
          label: '全部',
        },
        {
          value: 0,
          label: '待审核',
        },
        {
          value: 1,
          label: '审核通过',
        },
        {
          value: 2,
          label: '审核未通过',
        },
      ],
      businessScope: [
        { value: '全部', label: '全部' },
        {
          label: '门窗',
          value: '门窗',
        },
        {
          label: '柜体',
          value: '柜体',
        },
        {
          label: '房顶',
          value: '房顶',
        },
        {
          label: '地面',
          value: '地面',
        },
        {
          label: '墙体',
          value: '墙体',
        },
        {
          label: '软装',
          value: '软装',
        },
        {
          label: '硬装',
          value: '硬装',
        },
      ],
      tabList: [
        { name: '全部', id: -1, count: 0 },
        { name: '门店', id: 5, count: 0 },
        { name: '设计师', id: 1, count: 0 },
        { name: '材料仓', id: 4, count: 0 },
        { name: '安装队', id: 3, count: 0 },
        { name: '软件', id: 7, count: 0 },
        { name: '设备', id: 8, count: 0 },
        { name: '其他', id: 0, count: 0 },
      ],
      mainColum: [
        { prop: 'name', label: '名称', type: 'text', width: '100' },
        { prop: 'phone', label: '电话', type: 'text', width: '100' },
        { prop: 'tiktok_id', label: '抖音号', type: 'text', width: '100' },
        { prop: 'service_type', label: '分类', type: 'text', width: '100' },
        { prop: 'role_name', label: '标签', type: 'text', width: '100' },
        {
          prop: 'examine_status',
          label: '审核状态',
          type: 'temp',
          width: '100',
        },
        { prop: 'operation', label: '操作', type: 'temp', width: '100' },
      ],
      doorColumn: [
        { prop: 'name', label: '门店名称', type: 'text', width: '100' },
        { prop: 'phone', label: '电话', type: 'text', width: '100' },
        { prop: 'business', label: '经营范围', type: 'text', width: '100' },
        { prop: 'address', label: '地址', type: 'temp', width: '100' },
        {
          prop: 'examine_status',
          label: '审核状态',
          type: 'temp',
          width: '100',
        },
        { prop: 'operation', label: '操作', type: 'temp', width: '100' },
      ],
      designColumn: [
        { prop: 'name', label: '设计师名称', type: 'text', width: '100' },
        { prop: 'phone', label: '电话', type: 'text', width: '100' },
        { prop: 'address', label: '地址', type: 'temp', width: '100' },
        { prop: 'tag', label: '工作经验', type: 'text', width: '100' },
        { prop: 'business', label: '经营范围', type: 'text', width: '100' },
        {
          prop: 'examine_status',
          label: '审核状态',
          type: 'temp',
          width: '100',
        },
        { prop: 'operation', label: '操作', type: 'temp', width: '100' },
      ],
      installColumn: [
        { prop: 'name', label: '安装队名称', type: 'text', width: '100' },
        { prop: 'phone', label: '电话', type: 'text', width: '100' },
        { prop: 'address', label: '地址', type: 'temp', width: '100' },
        { prop: 'tag', label: '工作经验', type: 'text', width: '100' },
        {
          prop: 'examine_status',
          label: '审核状态',
          type: 'temp',
          width: '100',
        },
        { prop: 'operation', label: '操作', type: 'temp', width: '100' },
      ],
    }
  },
  computed: {
    orderStatusStyle() {
      return (status) => {
        return {
          width: '8px',
          height: '8px',
          'border-radius': '50%',
          backgroundColor: status ? '#00a854' : '#bfbfbf',
          display: 'inline-block',
          marginRight: '5px',
        }
      }
    },
    currentColums() {
      let cureent = []
      switch (this.currentType) {
        case 5:
          cureent = this.doorColumn
          break
        case 1:
          cureent = this.designColumn
          break
        case 3:
          cureent = this.installColumn
          break
      }
      return cureent
    },
  },
  created() {
    this.getList()
    this.getListCount()
  },
  methods: {
    // 获取列表
    getList() {
      this.$request.getExamineList(
        {
          service_type: this.currentType,
          ...this.changeForm,
          ...this.filterForm,
        },
        (res) => {
          console.log('res', res)
          this.tableData = res.data.data
          this.total_count = res.data.total
          this.tableData.forEach((i) => {
            switch (i.service_type) {
              case 0:
                i.service_type = '其他'
                break
              case 1:
                i.service_type = '设计师'
                break
              case 3:
                i.service_type = '安装'
                break
              case 4:
                i.service_type = '材料仓'
                break
              case 5:
                i.service_type = '门店'
                break
              case 7:
                i.service_type = '软件'
                break
              case 8:
                i.service_type = '设备'
                break
            }
          })
        }
      )
    },
    // 获取每个列表数量
    getListCount() {
      this.$request.getListNum({}, (res) => {
        console.log('数量', res)
        this.tabList[0].count = res.data.total_count
        this.tabList[1].count = res.data.shop_count
        this.tabList[2].count = res.data.designer_count
        this.tabList[3].count = res.data.material_count
        this.tabList[4].count = res.data.installer_count
        this.tabList[5].count = res.data.software_count
        this.tabList[6].count = res.data.equipment_count
        this.tabList[7].count = res.data.other_count
      })
    },
    // 下拉筛选
    filterselectChange(e) {
      console.log('arg', e)
      this.filterForm.examine_status = e
      console.log('this.filterForm.examine_status', this.filterForm)
      let payload = {
        ...this.changeForm,
        name: this.filterForm.name,
        examine_status: e,
      }
      this.$request.getExamineList(
        { ...payload, service_type: this.currentType },
        (res) => {
          console.log('res', res)
          this.tableData = res.data.data
          this.total_count = res.data.total
          this.tableData.forEach((i) => {
            switch (i.service_type) {
              case 0:
                i.service_type = '其他'
                break
              case 1:
                i.service_type = '设计师'
                break
              case 3:
                i.service_type = '安装'
                break
              case 4:
                i.service_type = '材料仓'
                break
              case 5:
                i.service_type = '门店'
                break
              case 7:
                i.service_type = '软件'
                break
              case 8:
                i.service_type = '设备'
                break
            }
          })
        }
      )
    },
    // 筛选搜索
    filterinputChange(e) {
      console.log('this.filterForm.examine_status', this.filterForm)
      let payload = {
        ...this.changeForm,
        name: e,
        examine_status: this.filterForm.examine_status
          ? this.filterForm.examine_status
          : 0,
      }
      this.$request.getExamineList(
        { ...payload, service_type: this.currentType },
        (res) => {
          console.log('res', res)
          this.tableData = res.data.data
          this.total_count = res.data.total
          this.tableData.forEach((i) => {
            switch (i.service_type) {
              case 0:
                i.service_type = '其他'
                break
              case 1:
                i.service_type = '设计师'
                break
              case 3:
                i.service_type = '安装'
                break
              case 4:
                i.service_type = '材料仓'
                break
              case 5:
                i.service_type = '门店'
                break
              case 7:
                i.service_type = '软件'
                break
              case 8:
                i.service_type = '设备'
                break
            }
          })
        }
      )
    },
    //审核通过
    handlerPass(row) {
      if (row.examine_status == 1) {
        this.$message.warning('审核已通过,不可操作')
        return
      } else if (row.examine_status == 2) {
        this.$message.warning('审核未通过,不可操作')
        return
      } else {
        this.$confirm(
          '审核通过后,该数据显示在四合院平台（若用户未进行抖音授权则相应数据展示为【-】）确定通过审核？',
          '审核通过',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            showClose: true,
          }
        )
          .then(() => {
            this.$request.getUpdateExamineStatus(
              { service_id: [row.id], examine_status: 1 },
              (res) => {
                if (res.status == 1) {
                  this.$message.success('审核通过')
                  this.getList()
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消通过',
            })
          })
      }

      console.log('row', row)
    },
    // 取消按钮
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.showNopass = false
    },
    // 审核驳回确认按钮
    sureShowNoPass(formName) {
      let row = this.rowListDate
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.getUpdateExamineStatus(
            {
              service_id: [row.id],
              examine_status: 2,
              examine_info: this.ruleForm.examine_info,
            },
            (res) => {
              if (res.status == 1) {
                this.$message.success('驳回成功')
                this.showNopass = false
                this.getList()
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 审核未通过
    handlerNoPass(row) {
      console.log('row', row)
      this.rowListDate = row
      if (row.examine_status == 1) {
        this.$message.warning('审核已通过,不可操作')
        return
      } else if (row.examine_status == 2) {
        this.$message.warning('审核未通过,不可操作')
        return
      } else {
        this.showNopass = true
      }
    },
    // 批量通过
    mulHandlerPass() {
      let arr = this.checkBox
      let arrIsSure = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].examine_status == 2 || arr[i].examine_status == 1) {
          arrIsSure.push(arr[i])
        }
      }
      let arrid = arr.map((i) => {
        return i.id
      })
      if (arrIsSure.length) {
        this.$message.error('请选择待审核的数据')
        return
      } else {
        this.$request.getUpdateExamineStatus(
          { service_id: arrid, examine_status: 1 },
          (res) => {
            if (res.status == 1) {
              this.$message.success('批量通过成功')
              this.getList()
            }
          }
        )
      }
    },
    // 批量驳回
    mulHandlerNopass() {
      let arr = this.checkBox
      let arrIsSure = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].examine_status == 2 || arr[i].examine_status == 1) {
          arrIsSure.push(arr[i])
        }
      }
      let arrid = arr.map((i) => {
        return i.id
      })
      if (arrIsSure.length) {
        this.$message.error('请选择待审核的数据')
        return
      } else {
        this.$request.getUpdateExamineStatus(
          { service_id: arrid, examine_status: 2 },
          (res) => {
            if (res.status == 1) {
              this.$message.success('批量驳回成功')
              this.getList()
            }
          }
        )
      }
    },
    // 多选批量
    handleSelectionChange(e) {
      console.log(e)
      this.checkBox = e
    },
    // 翻页
    handleCurrentChange(page) {
      this.changeForm.page = page
      this.getList()
    },
    // 调整每页条数
    handleSizeChange(value) {
      this.changeForm.limit = value
      this.getList()
    },
    // 切换tab
    changeTab(id) {
      this.currentType = id
      this.changeForm.page = 1
      this.filterForm = {
        name: '',
        examine_status: 3,
      }
      this.getList()
      // switch (id) {
      //   case 5:
      //     this.businessScope = [
      //       { value: '全部', label: '全部' },
      //       {
      //         label: '门窗',
      //         value: '门窗',
      //       },
      //       {
      //         label: '柜体',
      //         value: '柜体',
      //       },
      //       {
      //         label: '房顶',
      //         value: '房顶',
      //       },
      //       {
      //         label: '地面',
      //         value: '地面',
      //       },
      //       {
      //         label: '墙体',
      //         value: '墙体',
      //       },
      //       {
      //         label: '软装',
      //         value: '软装',
      //       },
      //       {
      //         label: '硬装',
      //         value: '硬装',
      //       },
      //     ]
      //     this.placeholderValue = '请输入你需要搜索的门店名称'
      //     break
      //   case 1:
      //     this.businessScope = [
      //       { value: '全部', label: '全部' },
      //       {
      //         value: '结构图',
      //         label: '结构图',
      //       },
      //       {
      //         value: '效果图',
      //         label: '效果图',
      //       },
      //     ]
      //     this.placeholderValue = '请输入你需要搜索的设计师名称'
      //     break
      //   case 3:
      //     this.placeholderValue = '请输入你需要搜索的安装队名称'
      //     break
      // }
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.applyChain {
  width: 100%;
  .main_apply {
    padding: 10px 30px;
    background: white;
    width: 100%;
    .tab_box {
      width: 100%;
      border-bottom: 1px solid rgba(128, 128, 128, 0.164);
      padding: 20px 0 10px 0;
      .head_tab_item {
        cursor: pointer;
        border: 1px solid rgba(128, 128, 128, 0.171);
        padding: 10px 30px;
      }
      .activeTab {
        background: rgb(247, 238, 230);
      }
    }
    .filter_type {
      padding: 20px 0;
      display: flex;
      align-items: center;
      .filter_item {
        display: flex;
        align-items: center;
        width: 20%;
        margin-left: 20px;
        .labelName {
          width: 84px;
        }
      }
    }
    .footer {
      padding: 30px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footer_left {
        > span {
          padding: 5px 20px;
          border: 1px solid rgba(128, 128, 128, 0.534);
        }
      }
    }
  }
}
</style>
